<template>
  <div class="main"
       ref="main">
    <!-- <div class="title">仓库信息</div> -->
    <div class="topbar">
      <div class="flex a-center"
           v-show="selectArrSize===0">
        <div class="topbar-btn m-r-25"
             @click.stop="onClickCreateStorage">
          <Icon custom="i-icon icon-add"
                size="32"
                class="m-r-8"></Icon>
          新增
        </div>
        <Input v-model="searchValue"
               placeholder="仓库名称"
               search
               @on-search="onSearch"
               style="width:280px" />
      </div>
      <div class="flex a-center"
           v-show="selectArrSize>0">
        <div class="topbar-btn"
             @click.stop="batchDeleteStorage">
          <Icon custom="i-icon icon-delete"
                size="32"
                class="m-r-8"></Icon>
          删除
        </div>
      </div>
    </div>
    <div class="list">
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="columns"
               :data="data"
               @on-select="onSelect"
               @on-select-cancel="onSelectCancel"
               @on-select-all="onSelectAll"
               @on-select-all-cancel="onSelectAll">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop="onClickEdit(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">编辑</Button>
            <Button @click.stop="onClickDeleteStorage(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">删除</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>
    <StorageCreateModal v-model="createVisible"
                        :storageId.sync="createStorageId"
                        @on-click-confirm="onClickConfirm"></StorageCreateModal>
  </div>
</template>

<script>
import { getStorageList, deleteStorage } from '../../../services/storageapi';
import StorageCreateModal from '../../../components/product/StorageCreateModal';
export default {
  components: { StorageCreateModal },
  data () {
    return {
      searchValue: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 80,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '仓库名称',
          key: 'name',
          tooltip: true,
          width: 240
        },
        {
          title: '仓库地址',
          key: 'location',
          tooltip: true,
          minWidth: 200
        },
        {
          title: '操作',
          slot: 'operation',
          width: 140,
          align: 'right'
        }
      ],
      data: [],
      selectArr: new Set(),
      selectArrSize: 0,

      createVisible: false,
      createStorageId: 0,
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 250
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 250
      }
      this.getStorageList()
    },
    onSelectAll (selection) {
      if (selection.length === 0) {
        let data = this.$refs.table.data
        data.forEach((item) => {
          if (this.selectArr.has(item.storageId)) {
            this.selectArr.delete(item.storageId)
          }
        })
      } else {
        selection.forEach(item => {
          this.selectArr.add(item.storageId)
        })
      }
      this.selectArrSize = this.selectArr.size
    },
    //  选中某一行
    onSelect (selection, row) {
      this.selectArr.add(row.storageId)
      this.selectArrSize = this.selectArr.size
    },
    //  取消某一行
    onSelectCancel (selection, row) {
      this.selectArr.delete(row.storageId)
      this.selectArrSize = this.selectArr.size
    },
    setChecked () {
      var objData = this.$refs.table.$refs.tbody.objData;
      for (let index in objData) {
        if (this.selectArr.has(objData[index].storageId)) {
          objData[index]._isChecked = true
        }
      }
    },
    onSearch () {
      this.selectArr = new Set()
      this.selectArrSize = 0
      this.pageNum = 1
      this.getStorageList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getStorageList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getStorageList()
    },
    // 获取仓库列表
    getStorageList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.searchValue) params.storageName = this.searchValue
      getStorageList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list.map(item => {
          item.location = item.prov ? item.prov + item.city + item.dist + item.address : ''
          return item
        })
        this.$nextTick(() => {
          this.setChecked();
        })
      })
    },
    // 点击删除仓库
    onClickDeleteStorage (row) {
      this.onDeleteStorage([row.storageId])
    },
    // 批量删除仓库
    batchDeleteStorage () {
      this.onDeleteStorage([...this.selectArr])
    },
    // 删除仓库
    onDeleteStorage (list) {
      this.$Modal.confirm({
        title: '删除仓库',
        content: '<p>是否删除仓库？</p>',
        onOk: () => {
          let params = {
            idList: list.join()
          }
          deleteStorage(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功')
              this.selectArr = new Set()
              this.selectArrSize = 0
              this.getStorageList()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 点击新建仓库
    onClickCreateStorage () {
      this.createStorageId = 0
      this.createVisible = true
    },
    // 点击编辑
    onClickEdit (row) {
      this.createStorageId = row.storageId
      this.createVisible = true
    },
    onClickConfirm () {
      this.createVisible = false
      this.getStorageList()
    }
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  background-color: #f9f9ff;
  .title {
    font-size: 22px;
    line-height: 66px;
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #d3d1e7;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .topbar-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      &:hover {
        color: #0652DD;
      }
    }
    ::v-deep {
      .ivu-select-selection,
      .ivu-input {
        background-color: transparent;
        border-color: #9c9ccd;
        border-radius: 18px;
      }
      .ivu-select-selection span {
        padding-left: 12px;
      }
      .ivu-input {
        padding: 4px 12px;
      }
    }
  }
  .list {
    padding: 0 20px 20px;
    .table {
      padding: 20px;
      background-color: #fff;
    }
    ::v-deep {
      .ivu-table-cell {
        padding: 0 8px;
      }
    }
  }
  ::v-deep .ivu-drawer {
    top: 64px;
    .ivu-drawer-body {
      padding: 0;
    }
  }
}
</style>